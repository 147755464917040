<template>
  <div class="table-wrap">
    <div class="search">
      <a-input-search
        v-model:value="queryParam.mapName"
        :placeholder="$t('LB_Coach_EnterName')"
        style="width: 220px"
        enter-button
        allow-clear
        @search="handleSearch"
      />
      <!-- 请输入名称 -->
    </div>
    <a-table
      :scroll="{ x: 884 }"
      :columns="columns"
      :data-source="state.dataSource"
      :rowKey="(record, index) => index"
      :bordered="false"
      :pagination="pagination"
      :loading="state.loading"
      @change="handleTableChange"
    >
      <template #taskName="{ text }">
        <a-tooltip placement="top">
          <template #title>{{ text }}</template>
          {{ text }}
        </a-tooltip>
      </template>
      <template #planTitle>
        {{ $t("teaching.table.schedule_completion") }}
        <!-- 安排计划完成 -->
        <a-tooltip placement="top">
          <template #title>
            {{ $t("teaching.arranged") }}/{{ $t("teaching.should_arrange") }}
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 2px" />
        </a-tooltip>
        <!-- 已安排/应安排 -->
      </template>
      <template #evaluateTitle>
        {{ $t("teaching.table.evaluate_students") }}
        <!-- 评价学员完成 -->
        <a-tooltip placement="top">
          <template #title>
            {{ $t("teaching.rated") }}/{{ $t("teaching.should_evaluate") }}
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 2px" />
        </a-tooltip>
        <!-- 已评价/应评价 -->
      </template>
      <template #sumTitle>
        {{ $t("teaching.table.summarize") }}
        <!-- 总结完成 -->
        <a-tooltip placement="top">
          <template #title>
            {{ $t("XB_Exam_PendingApproval") }}/{{ $t("CM_HadSubmit") }}/{{
              $t("teaching.should_submit")
            }}
          </template>
          <ExclamationCircleOutlined style="color: #999; padding-left: 2px" />
        </a-tooltip>
        <!-- 待批阅/已提交/应提交 -->
      </template>
      <template #plan="{ record }">
        <span v-if="record.planCompleted || record.planTotals"
          >{{ record.planCompleted }} / {{ record.planTotals }}</span
        >
        <span v-else>--</span>
      </template>
      <template #evaluate="{ record }">
        <span
          v-if="record.t2sEvaluationCompleted || record.t2sEvaluationTotals"
        >
          {{ record.t2sEvaluationCompleted }} /
          {{ record.t2sEvaluationTotals }}
        </span>
        <span v-else>--</span>
      </template>
      <template #sum="{ record }">
        <span
          v-if="
            record.reviewSummarizeTotals ||
            record.reviewSummarizeCompleted ||
            record.summarizeTotals
          "
        >
          {{ record.reviewSummarizeTotals }} /
          {{ record.reviewSummarizeCompleted + record.reviewSummarizeTotals }} /
          {{ record.summarizeTotals }}
        </span>
        <span v-else>--</span>
      </template>
      <template #action="{ record }">
        <div class="action">
          <a-button type="link" @click="handleInfo('plan', record)">{{
            $t("teaching.arrange_plan")
          }}</a-button>
          <!-- 安排计划 -->
          <a-button type="link" @click="handleInfo('evaluate', record)">{{
            $t("CM_evaluate_students")
          }}</a-button>
          <!-- 评价学员 -->
          <a-button type="link" @click="handleInfo('sum', record)">{{
            $t("teaching.review_summary")
          }}</a-button>
          <!-- 批阅总结 -->
        </div>
      </template>
    </a-table>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, toRaw } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { teachManageList } from "@/api/map";
export default {
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const stateData = reactive({
      columns: [
        {
          title: $t("teaching.table.map_name"),
          // 学习地图名称
          dataIndex: "taskName",
          slots: { customRender: "taskName" },
          width: 220,
          ellipsis: true,
        },
        {
          width: 220,
          align: "center",
          slots: {
            title: "planTitle",
            customRender: "plan",
          },
        },
        {
          width: 220,
          align: "center",
          slots: {
            title: "evaluateTitle",
            customRender: "evaluate",
          },
        },
        {
          width: 140,
          align: "center",
          slots: {
            title: "sumTitle",
            customRender: "sum",
          },
        },
        {
          title: $t("teaching.table.operate"),
          // 操作
          width: 390,
          align: "center",
          slots: { customRender: "action" },
        },
      ],
    });

    const getList = (params) => {
      let form = JSON.parse(JSON.stringify(queryParam));
      form.page = params.current;
      form.pageSize = params.pageSize;

      return teachManageList(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 30,
      tableSize: "middle", // 'default' | 'middle' | 'small'
      stripe: false,
    });

    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      if (sorter.field != undefined) {
        if (
          sorter.field != queryParam.sortKey ||
          sorter.order != queryParam.sort
        ) {
          current = 1;
        }
      }
      queryParam.sortKey = sorter.field;
      queryParam.sort = sorter.order;

      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });

      reload();
    };
    const { total, pageSize, current } = toRefs(state);

    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    let queryParam = reactive({
      mapName: "",
    });

    const handleSearch = () => {
      setPageInfo({ current: 1, ...toRaw(queryParam) });
      reload();
    };

    const handleInfo = (type, data) => {
      emit("handle", type, data);
    };

    return {
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      queryParam,
      handleSearch,
      handleInfo,
    };
  },
};
</script>

<style lang="less" scoped>
.table-wrap {
  .search {
    .mixinFlex(flex-end);
    margin: 8px 0 24px;

    ::v-deep(.ant-input-search) {
      padding-right: 1px;
      border-radius: 4px 0 0 4px;
    }

    ::v-deep(.ant-btn) {
      border-radius: 0 4px 4px 0;
    }
  }
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
